<template>
  <template>
    <Dialog
      v-model:visible="displayModal"
      :breakpoints="{'1300px': '100vw'}" :style="{width: '50vw'}"
      :modal="true"
      :closable="false"
    >
      <template #header>
        <div style="color:#1c3faa;">
          <h4>
            <i class="pi pi-table" style="font-size: 1.5rem;margin-right:10px"></i>
            <strong style="font-size:20px">Administrar Cajas</strong>
          </h4>
        </div>
      </template>
      <div class="pt-4 pb-4">
        <DataTable
          :value="boxes"
          class="p-datatable-sm text-xs"
          showGridlines
          responsiveLayout="scroll"
          editMode="row"
          @row-edit-save="onRowEditSave"
          v-model:editingRows="editingRows"
          @row-edit-cancel="onRowEditCancel"
        >
          <template #empty>
            No hay datos disponibles para mostrar.
          </template>
          <template #header>
            <div class="flex justify-end align-items-center">
            <span class="p-input-icon-left">
              <Button icon="pi pi-plus" label="Agregar Caja" @click="addBox"
                      class="p-button-outlined p-button-success p-button-sm"/>
            </span>
            </div>
          </template>
          <Column field="nombre" header="Nombre" style="min-width:7rem" headerStyle="width:15%"
                  bodyStyle="text-align:center">
            <template #body="{data}">
              <div class="grid grid-cols-1 w-full">
                <div class="flex justify-content-evenly items-center my-1">
                  <div>
                    {{data.nombre}}
                  </div>
                  <div class="flex gap-2">
                    <Badge v-if="existValidate(data.indice)" value="Sin asignar"
                           class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                    <Badge v-else value="Con asignación"
                           class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"/>
                  </div>
                </div>
              </div>
            </template>
            <template #editor="{data}">
              <div class="grid grid-cols-1 w-full">
                <div class="flex justify-content-evenly items-center my-1">
                  <div>
                    {{data.nombre}}
                  </div>
                  <div class="flex gap-2">
                    <Badge v-if="existValidate(data.indice)" value="Sin asignar"
                           class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                    <Badge v-else value="Con asignación"
                           class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"/>
                  </div>
                </div>
              </div>
            </template>
          </Column>
          <Column field="tipo" header="Tipo" style="min-width:7rem" headerStyle="width:15%"
                  bodyStyle="text-align:center">
            <template #body="slotProps">
              <div v-if="!slotProps.data.tipo">
                <span>Presione el botón de editar para agregar un tipo.</span>
              </div>
              <div v-else>
                <Dropdown
                  optionLabel="type"
                  optionValue="id"
                  @change="onChageBox($event, slotProps)"
                  :options="optionsType"
                  disabled
                  v-model="slotProps.data.tipo"
                  class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                  panelClass="text-xxs"
                  inputClass="p-inputtext-xxs text-xxs"
                  placeholder="Seleccione un tipo..."
                />
              </div>
            </template>
            <template #editor="slotProps">
              <div>
                <Dropdown
                  optionLabel="type"
                  optionValue="id"
                  @change="onChageBox($event, slotProps)"
                  :options="optionsType"
                  v-model="slotProps.data.tipo"
                  class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                  panelClass="text-xxs"
                  inputClass="p-inputtext-xxs text-xxs"
                  placeholder="Seleccione un tipo..."
                />
              </div>
            </template>
          </Column>
          <Column field="rangoTemp" header="Temperatura" style="min-width:7rem" headerStyle="width:15%"
                  bodyStyle="text-align:center">
            <template #body="slotProps">
              <div v-if="!slotProps.data.rangoTemp">
                <span>Presione el botón de editar para agregar un rango de temperatura.</span>
              </div>
              <div v-else>
                <Dropdown
                  optionLabel="rango"
                  optionValue="id"
                  disabled
                  :options="optionsTemperatura"
                  v-model="slotProps.data.rangoTemp"
                  class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                  panelClass="text-xxs"
                  inputClass="p-inputtext-xxs text-xxs"
                  placeholder="Seleccione un rango de temperatura..."
                />
              </div>
            </template>
            <template #editor="{data}">
              <div>
                <Dropdown
                  optionLabel="rango"
                  optionValue="id"
                  :options="data.tipo === 1 ? optionsTemperatura.filter((x) => x.id !== 4) : optionsTemperatura.filter((x) => x.id === 4)"
                  v-model="data.rangoTemp"
                  class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                  panelClass="text-xxs"
                  inputClass="p-inputtext-xxs text-xxs"
                  placeholder="Seleccione un rango de temperatura..."
                />
              </div>
            </template>
          </Column>
          <Column header="Acciones" :rowEditor="true" style="min-width:7rem" headerStyle="width:5%"
                  bodyStyle="text-align:center">
            <template #body="slotProps">
              <div>
                <Button @click="editingRows = [slotProps.data]" icon="pi pi-pencil"
                        class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Editar'"/>
                <Button icon="pi pi-minus-circle" @click="deleteBoxes(slotProps)"
                        class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Eliminar'"/>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex justify-center mt-2">
        <Button icon="pi pi-times" label="Cancelar" class="p-button-outlined p-button-sm p-button-danger mr-2" @click="closeResponsive"/>
        <Button icon="pi pi-check-square" label="Confirmar" class="p-button-outlined p-button-sm" @click="onSubmit"/>
      </div>
    </Dialog>
  </template>
</template>

<script>
import { ref } from 'vue'
import Swal from 'sweetalert2'

export default {
  name: 'modalBoxes',
  emits: ['deleteAsignMx'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const boxes = ref([])
    const cUsadas = ref([])
    const editingRows = ref([])
    const optionsTemperatura = ref([
      { id: 1, rango: 'MAYOR DE 1° & MENOR O IGUAL A 8°' },
      { id: 2, rango: 'MENOR DE 1° & MENOR O IGUAL A -5°' },
      { id: 3, rango: 'MENOR DE 1° & MENOR O IGUAL A -25°' },
      { id: 4, rango: 'N/A' }
    ])
    const optionsType = ref([
      { id: 1, type: 'CADENA FRIO' },
      { id: 2, type: 'N/A' }
    ])
    const openModalCajas = (values, boxesUsed) => {
      boxes.value = values
      cUsadas.value = boxesUsed
      displayModal.value = true
    }
    /* Evento de funcion cuando se cierra el modal */
    const closeResponsive = () => {
      displayModal.value = false
    }
    const addBox = () => {
      if (boxes.value.some((x) => !x.tipo || !x.rangoTemp)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Faltan campos por completar antes de agregar una nueva caja, por favor revisar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const rowIndice = boxes.value.length > 0 ? maxNumCaja(boxes.value) : 0
      boxes.value.push({
        id: null,
        indice: rowIndice + 1,
        rowIndex: boxes.value.length + 1,
        nombre: `Número ${rowIndice + 1}`,
        tipo: null,
        tipoT: null,
        rangoTemp: null,
        rangoTempT: null
      })
    }
    const maxNumCaja = (arrayNum) => {
      const numeros = arrayNum.map(objeto => objeto.rowIndex)
      return Math.max(...numeros)
    }
    const onChageBox = ({ value }, { index }) => {
      // si el tipo es 2(N/A) por defecto el rango de temperatura es N/A
      if (value === 2) {
        boxes.value[index].rangoTemp = 4
      } else {
        boxes.value[index].rangoTemp = 1
      }
    }
    const existValidate = (indice) => {
      return !cUsadas.value.includes(indice)
    }
    const onRowEditSave = ({ data }) => {
      if (cUsadas.value.includes(data.indice)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se permite editar una caja que ya contenga medicamentos asignados, por favor desasignelos e inténtelo de nuevo',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        data.tipo = data.tipoT
        data.rangoTemp = data.rangoTempT
        return
      }
      if (!data.tipo && !data.rangoTemp) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Faltan campos por diligenciar por favor revise.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
      if (!data.tipo === 1) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Faltan campos por diligenciar por favor revise.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
      data.tipoT = data.tipo
      data.rangoTempT = data.rangoTemp
    }
    const deleteBoxes = ({ index, data }) => {
      if (editingRows.value.length >= 1) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se pueden eliminar cajas mientras se esta editando un registro.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const text = boxes.value.some((x) => x.indice > data.indice) ? 'Las cajas restantes se re organizaran automáticamente.' : ''
      const usedBox = cUsadas.value.includes(data.indice) ? 'Esta caja contiene distribución de medicamentos asignada, al eliminarla los medicamentos que contiene se deberán volver asignar.<br>' : ''
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        html: `${usedBox} ¿Desea eliminar la caja: <strong>${data.nombre}</strong>?.<br> ${text}`,
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          boxes.value.splice(index, 1)
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Se eliminó la caja correctamente',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          }).then((response) => {
            if (text !== '') {
              boxes.value.forEach((element, index) => {
                element.nombre = `Número ${index + 1}`
                element.rowIndex = index + 1
              })
              emit('deleteAsignMx', data.indice)
            }
          })
        }
      })
    }
    const onRowEditCancel = ({ data, index }) => {
      data.tipo = data.tipoT
      data.rangoTemp = data.rangoTempT
      console.log(data, '--> data')
    }
    const onSubmit = () => {
      if (boxes.value.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debe agregar al menos una caja para poder confirmar, por favor revise.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (boxes.value.some((x) => !x.tipo && !x.rangoTemp)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Faltan campos por diligenciar por favor revise.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (editingRows.value.length >= 1) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se permite confirmar las cajas sin mientras se está editando un registro.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      displayModal.value = false
    }
    return {
      openModalCajas,
      displayModal,
      closeResponsive,
      addBox,
      boxes,
      optionsTemperatura,
      optionsType,
      onChageBox,
      onRowEditSave,
      editingRows,
      onSubmit,
      deleteBoxes,
      cUsadas,
      existValidate,
      onRowEditCancel
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
